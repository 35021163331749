var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ces"},[_c('van-icon',{staticClass:"back",attrs:{"name":"arrow-left"},on:{"click":function($event){return _vm.$router.push('/')}}}),_vm._m(0),_vm._m(1),_c('div',{staticClass:"ces-products"},[_vm._m(2),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":"https://site-cdn.huami.com/files/amazfit/mobile/assets/T-Rex.png","alt":""}}),_c('div',{staticClass:"intro"},[_vm._m(3),_c('router-link',{staticClass:"link",attrs:{"to":"/products/t-rex"}},[_vm._v("了解更多")])],1),_c('div',{staticClass:"describe"},[_vm._v("12 项军规认证 | 户外风格设计 | 20 天超长续航")])]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":"https://site-cdn.huami.com/files/amazfit/mobile/assets/PowerBuds.png","alt":""}}),_c('div',{staticClass:"intro"},[_vm._m(4),_c('router-link',{staticClass:"link",attrs:{"to":"/products/powerbuds"}},[_vm._v("了解更多")])],1),_c('div',{staticClass:"describe"},[_vm._v("运动心率监测 | 磁吸运动耳挂 | 澎湃震撼音效")])]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":"https://site-cdn.huami.com/files/amazfit/mobile/assets/ZenBuds.png","alt":""}}),_c('div',{staticClass:"intro"},[_vm._m(5),_c('router-link',{staticClass:"link",attrs:{"to":"/products/zenbuds"}},[_vm._v("了解更多")])],1),_c('div',{staticClass:"describe"},[_vm._v("耳塞遮噪设计 | 无感佩戴 | 音律助眠 | 心率辅助睡眠监测")])])]),_c('div',{staticClass:"ces-end"},[_vm._v("欢迎莅临现场展台体验 Amazfit 新品")])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ces-top"},[_c('h1',[_vm._v("跃过山丘")]),_c('h2',[_vm._v("Leap Over Limits")]),_c('p',[_vm._v("CES 2020 Amazfit 全球新品发布")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ces-info"},[_c('div',{staticClass:"title"},[_c('p'),_c('h2',[_vm._v("展会信息")]),_c('p')]),_c('p',{staticClass:"p1"},[_vm._v("LVCC South Hall 3")]),_c('p',{staticClass:"p2"},[_vm._v("Booth No.30369 / 2020.1.7-1.10")]),_c('p',{staticClass:"p3"},[_vm._v("美国拉斯维加斯会展中心 南3号馆 N0.30369")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('p'),_c('h2',[_vm._v("新品信息")]),_c('p')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"i1"},[_c('p',{staticClass:"p1"},[_vm._v("Amazfit T-Rex")]),_c('p',{staticClass:"p2"},[_vm._v("经久不衰 征战旷野")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"i1"},[_c('p',{staticClass:"p1"},[_vm._v("Amazfit PowerBuds")]),_c('p',{staticClass:"p2"},[_vm._v("澎湃音质 随心所动")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"i1"},[_c('p',{staticClass:"p1"},[_vm._v("Amazfit ZenBuds")]),_c('p',{staticClass:"p2"},[_vm._v("入耳入梦")])])
}]

export { render, staticRenderFns }